<script>

export default {
	name: "EchartsIcon",
	data() {
		return {
			oss: process.env.VUE_APP_URL_OSS + "student-management/",
		};
	},
	props: {
		text: {
			type: String,
			default: "",
		},
	},
};
</script>

<template>
	<div style="display: flex;align-items: center;margin-bottom: 16rem">
		<img :src="oss+'icon.png'" alt="" class="icon">
		<span style="font-weight: normal;font-size: 24px;color: #1E3AA2;">{{ text }}</span>
	</div>
</template>

<style lang="scss" scoped>
.icon {
	width: 24rem;
	height: 24rem;
	margin: 0 12rem;
}
</style>