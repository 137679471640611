<script>
import * as echarts from "echarts";
import TResult from "@/components/Result.vue";

export default {
	name: "Mbti",
	components: {TResult},
	props: {
		value: {
			type: Object | Array,
			default: function () {
				return {
					MBTI: [],
				};
			},
		},
	},
	watch: {
		value(newValue) {
			if (this.getType(newValue) !== "object" || newValue.MBTI.length === 0) return;
			const arr = ["E", "P", "T", "N", "I", "J", "F", "S"];
			let pie = [];
			arr.forEach((item, index) => {
				pie[index] = newValue.MBTI.pie.find(item_ => item_.letter === item).score;
			});
			let data = newValue.MBTI.analysis.flatMap(subArray => subArray);
			data.sort((a, b) => {
				const indexA = arr.indexOf(a.letter);
				const indexB = arr.indexOf(b.letter);
				return indexA - indexB;
			});
			const values = data.map(item => item.percentage);
			let indicator = [
				{name: "外向（E）", max: 27},
				{name: "感知（P）", max: 27},
				{name: "思考（T）", max: 27},
				{name: "直觉（N）", max: 27},
				{name: "内向（I）", max: 27},
				{name: "判断（J）", max: 27},
				{name: "情感（F）", max: 27},
				{name: "感觉（S）", max: 27},
			];
			indicator.forEach((item, index) => {
				item.show = values[index];
			});
			this.$nextTick(() => {
				let chartDom = document.getElementById("mbti");
				if (!chartDom) return;
				let myChart = echarts.init(chartDom);
				let option = {
					radar: {
						indicator: indicator,
						radius: window.innerWidth < 1700 ? 60 : 93,
						name: {
							formatter: function (name, ticket) {
								console.info(ticket, "🚀 ~ file:Mbti.vue,line:52");
								return `{a|${name}}` + `\n\n{b|指数  }` + `{c|${ticket.show}}`;
							},
							rich: {
								a: {
									color: "#222",
									fontSize: 14,
									lineHeight: 1,
								},
								b: {
									fontSize: 14,
								},
								c: {
									color: "#3491FA",
									fontSize: 14,
								},
							},
						},
					},
					series: [{
						type: "radar",
						data: [{
							value: pie,
							itemStyle: {
								normal: {
									color: "#1C4EFD",
									borderColor: "#1C4EFD",
								},
							},
						}],
						areaStyle: {
							color: "#AAC4FF",
						},
					}],
				};
				option && myChart.setOption(option);
			});
		},
	},

	methods: {
		getType(value) {
			return Object.prototype.toString.call(value).slice(8, -1).toLowerCase();
		},
	},
};
</script>

<template>
	<div v-if="getType(value.MBTI) === 'object'" class="mbti-main">
		<div style="display: flex;flex-direction: column">
			<div style="font-weight: normal;font-size: 24rem;color: #1E3AA2;margin-bottom: 73rem">MBTI测试结果</div>
			<div style="font-weight: normal;font-size: 30rem;color: #1C4EFD;flex: 1">
				<div>{{ value.MBTI.mbti.result }}-{{ value.MBTI.mbti.evaluate }}</div>
				<div v-for="i in value.MBTI.mbti.personality" style="width: 70rem;height: 32rem;background: linear-gradient( 270deg, #908EFF 0%, #BDBCFF 100%), #D8D8D8;border-radius: 4rem;font-size: 16rem;color: #fff;text-align: center;line-height: 32rem">
					{{ i }}
				</div>
			</div>
			<div style="font-weight: normal;font-size: 14rem;color: #888888;">测试时间：{{ value.MBTI.mbti.completion_at }}</div>
		</div>
		<div id="mbti"></div>
	</div>
	<div v-else class="mbti-main" style="flex-direction: column">
		<div style="font-weight: normal;font-size: 24rem;color: #1E3AA2;">MBTI测试结果</div>
		<t-result style="margin-top: -68rem" text="暂无数据" type="empty"></t-result>
	</div>
</template>

<style lang="scss" scoped>
.mbti-main {
	width: 642rem;
	height: 410rem;
	background: #FFFFFF;
	border-radius: 8rem;
	padding: 24rem;
	box-sizing: border-box;
	display: flex;
}

#mbti {
	flex: 1;
}
</style>
