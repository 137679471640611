<script>
import * as echarts from "echarts";
import TResult from "@/components/Result.vue";
import EchartsIcon from "@/views/edu/edu-info/student-manager/components/Echarts/EchartsIcon.vue";

export default {
	name: "MoralHabit",
	components: {TResult, EchartsIcon},
	data() {
		return {
			selected: 1,
			params: {id: this.$route.query.id, type: 1, subject_id: ""},
			echartsData: [],
		};
	},
	props: {
		value: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	watch: {
		value(newValue) {
			newValue.subject.forEach(i => {
				if (i.show === 1) {
					this.params.subject_id = i.subject_id;
					this.init();
				}
			});
			if (!newValue.subject.length) {
				this.params.subject_id = 0;
				this.init();
			}
		},
	},
	methods: {
		onSelect(index) {
			this.params.type = index;
			this.init();
		},
		onSelectTwo() {
			this.init();
		},
		changeShow(val) {
			this.selected = val;
			this.$nextTick(() => {
				let chartDom = document.getElementById("main2");
				let data = val ? this.echartsData.map(item => item.total_score) : this.echartsData.map(item => item.total_rank);
				if (!chartDom) return;
				let myChart = echarts.init(chartDom);
				let option = {
					grid: {
						left: "3%",
						right: "5%",
						top: "24px",
						bottom: "0",
						containLabel: true,
					},
					tooltip: {
						trigger: "axis",
						formatter: function (params) {
							return `<div style="display: flex;align-items: center;">
										<div style="display: block">${params[0].name}</div>
							</div>` + `<div style="display: flex;align-items: center"><div style='display: block;width: 4rem;height: 4rem;background: #23C343;border-radius: 10rem;margin-right: 12rem'>
												</div>${(val ? "本次分数   " : "本次排名   ") + params[0].data}</div>`;
						},
					},
					xAxis: [
						{
							type: "category",
							boundaryGap: false,
							data: this.echartsData.map(item => item.exam_name),
							show: false,
						},
					],
					yAxis: [{type: "value"}],
					series: [
						{
							type: "line",
							data: data,
							areaStyle: {
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
									offset: 0,
									color: "#2FD932",
								}, {
									offset: 1,
									color: "#fff",
								}]),
							},
							lineStyle: {
								color: "#2FD932",
							},
							itemStyle: {
								color: "#2FD932",
							},
						},
					],
				};
				option && myChart.setOption(option);
			});
		},
		init() {
			this.$_axios("/students/score-rank", {params: {...this.params}}).then(res => {
				this.echartsData = res.data.data.score_ranks || [];
				let val = this.selected;
				let data = val ? this.echartsData.map(item => item.total_score) : this.echartsData.map(item => item.total_rank);
				this.$nextTick(() => {
					let chartDom = document.getElementById("main2");
					if (!chartDom) return;
					let myChart = echarts.init(chartDom);
					let option = {
						grid: {
							left: "3%",
							right: "5%",
							top: "24px",
							bottom: "0",
							containLabel: true,
						},
						tooltip: {
							trigger: "axis",
							formatter: function (params) {
								console.info(params[0].data, "🚀 ~ file:MoralHabit.vue,line:152");
								return `<div style="display: flex;align-items: center;">
										<div style="display: block">${params[0].name}</div>
							</div>` + `<div style="display: flex;align-items: center"><div style='display: block;width: 4rem;height: 4rem;background: #23C343;border-radius: 10rem;margin-right: 12rem'>
												</div>${(val ? "本次分数   " : "本次排名   ") + params[0].data}</div>`;
							},
						},
						xAxis: [
							{
								type: "category",
								boundaryGap: false,
								data: this.echartsData.map(item => item.exam_name),
								show: false,
							},
						],
						yAxis: [{type: "value"}],
						series: [
							{
								type: "line",
								data: data,
								areaStyle: {
									color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: "#2FD932",
									}, {
										offset: 1,
										color: "#fff",
									}]),
								},
								lineStyle: {
									color: "#2FD932",
								},
								itemStyle: {
									color: "#2FD932",
								},
							},
						],
					};
					option && myChart.setOption(option);
				});
			});
		},
	},
};
</script>

<template>
	<div>
		<EchartsIcon text="成绩变动情况"></EchartsIcon>
		<div style="width: 992rem;height: 460rem;background: #FFFFFF;border-radius: 8rem;display: flex;flex-direction: column">
			<div style="display: flex;align-items: center;justify-content: space-between;padding: 24rem 24rem 0">
				<div style="width: 142rem;height: 40rem;background: #F9FAFD;border-radius: 0 4rem 4rem 0;display: flex">
					<div :class="params.type === 1?'selected':''" class="text-1" @click="onSelect(1)">统考</div>
					<div :class="params.type === 2?'selected':''" class="text-1" @click="onSelect(2)">月考</div>
				</div>
				<div style="display:flex;">
					<el-select v-model="params.subject_id" style="width: 160rem;margin-right: 24rem" @change="onSelectTwo">
						<el-option :value="0" label="全部"></el-option>
						<el-option v-for="i in value.subject" :label="i.name" :value="i.subject_id"></el-option>
					</el-select>
					<div style="width: 142rem;height: 40rem;background: #F9FAFD;border-radius: 148rem;display: flex;padding: 4rem">
						<div :class="selected?'selected-1':''" class="text-2" style="border-radius: 148rem" @click="changeShow(1)">分数</div>
						<div :class="!selected?'selected-1':''" class="text-2" style="border-radius: 148rem" @click="changeShow(0)">排名</div>
					</div>
				</div>
			</div>
			<div v-if="echartsData.length" id="main2" style="flex: 1;width: 100%"></div>
			<t-result v-else style="width: 100%;padding: 60rem 0;" text="暂无数据" type="empty"></t-result>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.text-1 {
	width: 72rem;
	height: 40rem;
	border-radius: 4rem;
	font-weight: normal;
	font-size: 14rem;
	line-height: 40rem;
	text-align: center;
	color: #888888;
}

.text-2 {
	width: 72rem;
	height: 32rem;
	border-radius: 4rem;
	font-weight: normal;
	font-size: 14rem;
	line-height: 32rem;
	text-align: center;
	color: #888888;
}

.selected {
	background: #E1EAFF;
	color: #1C4EFD;
}

.selected-1 {
	background: #1C4EFD;
	color: #E1EAFF;
}
</style>
