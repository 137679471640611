<script>

import TableTemplate from "@/components/table/TableTemplate.vue";

export default {
	name: "HistoricalAchievements",
	components: {TableTemplate},
	props: {
		allInfo: {
			type: Object,
			default: () => {
				return {
					achievement: [],
				};
			},
		},
	},
	data() {
		return {
			oss: process.env.VUE_APP_URL_OSS + "student-management/",
			tableConfig: [
				{
					prop: "exam_name",
					label: "考试名称",
					width: "180px",
				},
				{
					prop: "exam_type_name",
					label: "考试类型",
					width: "120px",
				},
				{
					prop: "score",
					label: "总成绩",
					width: "80px",
				},
				{
					prop: "语文",
					label: "语文",
					width: "80px",
				},
				{
					prop: "数学",
					label: "数学",
					width: "80px",
				},
				{
					prop: "外语",
					label: "外语",
					width: "80px",
				},
				{
					prop: "历史",
					label: "历史",
					width: "80px",
				},
				{
					prop: "地理",
					label: "地理",
					width: "80px",
				},
				{
					prop: "政治",
					label: "政治",
					width: "80px",
				},
				{
					prop: "物理",
					label: "物理",
					width: "80px",
				},
				{
					prop: "体育",
					label: "体育",
				},
				{
					prop: "美术",
					label: "美术",
				},
				{
					prop: "信息技术",
					label: "信息技术",
				},
				{
					label: "操作",
					width: "120px",
					handle: true,
					fixed: "right",
					render: () => ["查看成绩分析"],
				},
			],
			tableData: this.allInfo.achievement,
			studyTarget: [],
		};
	},
	watch: {
		allInfo() {
			this.tableData = this.allInfo.achievement;
		},
	},
	methods: {
		tableHandle(row) {
			this.$router.push({
				path: "/edu/edu-info/student-manager/achievement-analysis",
				query: {
					student_no: this.allInfo.base.student_no,
					exam_id: row.exam_id,
				},
			});
		},

		toLink() {
			this.$router.push({
				path: "/edu/edu-info/student-manager/achievement",
				query: {
					id: this.$route.query.id,
					student_no: this.allInfo.base.student_no,
				},
			});
		},
	},
	created() {
		this.$_axios2.get("api/student/details/study-target?student_id=" + this.$route.query.id).then(res => {
			this.studyTarget = res.data.data;
		});
	},
};
</script>

<template>
	<div v-if="allInfo.achievement && allInfo.achievement.length" class="main">
		<div style="display: flex;justify-content: space-between;align-items: center;margin-bottom: 24rem">
			<div class="title">
				<span>考试成绩历史记录</span>
			</div>
			<a class="content" href="javascript:" @click="toLink">
				更多
				<i class="el-icon-d-arrow-right"></i>
			</a>
		</div>
		<table-template :table-config="tableConfig" :table-data="tableData" @onHandle="tableHandle"></table-template>
		<div v-if="studyTarget.length !== 0" style="display: flex;margin-top: -40px">
			<div style="flex: 1;height: 56rem;background: #ECF9EE;border-top: 1rem solid #2FD932;line-height: 56rem;font-weight: 400;font-size: 16px;color: #23C343;padding: 0 20px;display: flex">
				<div style="width: 180px;display: flex;align-items: center">
					<img :src="oss +'positioning.png'" alt="" style="width: 20rem;height: 20rem;margin-right: 24rem">
					学习目标分数
				</div>
				<div style="width: 120px"></div>
				<div v-for="(i) of studyTarget" style="width: 80px">{{ i }}</div>
			</div>
			<div style="width: 120px;height: 56rem;background: #ECF9EE;border-top: 1rem solid #2FD932;box-shadow: rgba(0, 0, 0, 0.2) -1px 0 3px"></div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.main {
	width: 100%;
	min-height: 100rem;
	background: #FFFFFF;
	border-radius: 8rem;
	padding: 24rem;
	box-sizing: border-box;

	.title {
		font-weight: 600;
		font-size: 24rem;
		color: #1E3AA2;
	}

	.content {
		font-size: 16rem;
		color: #326FFF;
	}
}

.t-list {
	width: 100%;
	padding: 0 !important;
}

::v-deep .t-table {
	margin-right: 0 !important;
}
</style>