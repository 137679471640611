<script>
import * as echarts from "echarts";
import TResult from "@/components/Result.vue";
import EchartsIcon from "@/views/edu/edu-info/student-manager/components/Echarts/EchartsIcon.vue";

export default {
	name: "ChangeInPerformance",
	components: {TResult, EchartsIcon},
	data() {
		return {
			chartData: [],
			knack_type: "",
			subject_id: "",
		};
	},
	props: {
		value: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	watch: {
		value(newValue) {
			newValue.subject.forEach(i => {
				if (i.show === 1) {
					this.subject_id = i.subject_id;
				}
			});
			newValue.knack_list.forEach(i => {
				if (i.show === 1) {
					this.knack_type = i.type;
				}
			});
			if (!newValue.subject.length) {
				this.params.subject_id = 0;
			}
			this.getChartData();
		},
	},
	methods: {
		onSelect(val) {
			this.knack_type = val.type;
			this.value.knack_list.forEach(i => {
				i.show = 0;
			});
			val.show = 1;
			this.getChartData();
		},
		onSelectTwo(val) {
			this.subject_id = val;
			this.getChartData();
		},
		init(data) {
			let chartDom = document.getElementById("main3");
			if (!chartDom) return;
			let myChart = echarts.init(chartDom);
			const updatedData = data.map(i => {
				if (i.level === 10) {
					i.level = 40;
				} else if (i.level === 20) {
					i.level = 30;
				} else if (i.level === 30) {
					i.level = 20;
				} else if (i.level === 40) {
					i.level = 10;
				}
				return i;
			});
			let option = {
				grid: {
					left: "3%",
					right: "5%",
					top: "24px",
					bottom: "0",
					containLabel: true,
				},
				tooltip: {
					trigger: "axis",
					formatter: function (params) {
						return `<div style="display: flex;align-items: center">
										<div style="width: 6rem;height: 6rem;background: #BBCBFF;border-radius: 50%;margin-right: 10rem;margin-top: -6rem"></div>
										<div>等级  ${params[0].value === 10 ? "差" : params[0].value === 20 ? "中" : params[0].value === 30 ? "良" : params[0].value === 40 ? "优" : ""}</div>
							</div>` + `<span style='font-size: 14rem;color: #888888'><span style='margin-right: 10rem;margin-left: 14rem'>日期</span>${params[0].name}</span>`;
					},
				},
				xAxis: {
					type: "category",
					data: data.map(i => i.month),
				},
				yAxis: {
					type: "value",
					axisLabel: {
						formatter: function (value) {
							return value === 0 ? "" : (value === 10 ? "差" : value === 20 ? "中" : value === 30 ? "良" : value === 40 ? "优" : "");
						},
					},
					min: 0,
					max: 40, // 设置y轴的最大值，以确保显示所有刻度
					splitNumber: 4, // 设置刻度的数量
				},

				series: [
					{
						data: updatedData.map(i => i.level),
						type: "bar",
						itemStyle: {
							barBorderRadius: 4,
							color: {
								type: "linear",
								x: 0,
								y: 0,
								x2: 1,
								y2: 1,
								colorStops: [
									{offset: 0, color: "#6586F5"},
									{offset: 1, color: "#7595FF"},
								],
							},
						},
						barWidth: 40,
					},
				],
			};
			myChart.setOption({
				...option,
			});
		},
		getChartData() {
			this.$_axios2.get("api/student/details/task-wont", {params: {student_id: this.$route.query.id, knack_type: this.knack_type, subject_id: this.subject_id}}).then(res => {
				this.chartData = res.data.data;
				this.$nextTick(() => {
					this.init(this.chartData);
				});
			});
		},
	},

};
</script>

<template>
	<div>
		<EchartsIcon text="学习习惯检查"></EchartsIcon>
		<div style="width: 600rem;height: 460rem;background: #FFFFFF;padding: 24rem;box-sizing: border-box;display: flex;flex-direction: column">
			<div style="display: flex;align-items: center;justify-content: space-between">
				<div style="height: 40rem;background: #F9FAFD;border-radius: 0 4rem 4rem 0;display: flex">
					<div v-for="i in value.knack_list" :class="i.show?'selected':''" class="text-1" @click="onSelect(i)">{{ i.name }}</div>
				</div>
				<div>
					<el-select v-model="subject_id" style="width: 160rem" @change="onSelectTwo">
						<el-option :value="0" label="全部"></el-option>
						<el-option v-for="i in value.subject" :label="i.name" :value="i.subject_id"></el-option>
					</el-select>
				</div>
			</div>
			<div v-if="chartData.length" id="main3" style="flex: 1;width: 100%"></div>
			<t-result v-else style="width: 100%;padding: 70rem 0;" text="暂无数据" type="empty"></t-result>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.text-1 {
	min-width: 72rem;
	height: 40rem;
	border-radius: 4rem;
	font-weight: normal;
	font-size: 14rem;
	line-height: 40rem;
	text-align: center;
	color: #888888;
}

.selected {
	background: #E1EAFF;
	color: #1C4EFD;
}
</style>
