<script>
import TResult from "@/components/Result.vue";

export default {
	name: "ClassSchedule1",
	components: {TResult},
	data() {
		return {};
	},
	props: {
		value: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	methods: {
		bg: function (n) {
			const gradients = {
				A: "linear-gradient(60deg, #908EFF 0%, #BDBCFF 100%), #D8D8D8",
				B: "linear-gradient(270deg, #FFB68E 0%, #FFA647 99%), #D8D8D8",
				C: "linear-gradient(60deg, #8DD17E 0%, #B0DDA6 100%), #D8D8D8",
				D: "linear-gradient(60deg, #37B9FE 0%, #59C5FF 100%), #D8D8D8",
				"培优": "linear-gradient( 60deg, #C876FF 0%, #D597FF 99%), #D8D8D8",
				"艺体": "linear-gradient(60deg, #FF9D7C 0%, #F66B3C 100%), #D8D8D8",
			};
			return gradients[n];
		},
	},
};
</script>

<template>
	<div class="ClassSchedule-main">
		<div style="display: flex;justify-content: space-between">
			<span style="font-size: 24rem;color: #1E3AA2;font-weight: 600">学习科目与班级</span>
			<a href="javascript:" style="font-size: 16rem;color: #326FFF;" @click="$router.push('/edu/school-timetable/student/detail?type=1&id=' + $route.query.id)">查看当前课程表 <i class="el-icon-d-arrow-right"></i></a>
		</div>
		<div v-if="value.subject_class&&value.subject_class.length" style="display: grid; grid-template-columns: repeat(6, 1fr);">
			<div v-for="(i, n) in value.subject_class" :key="n" class="item-box">
				<div style="display: flex;align-items: center">
					<img :src="i.pc_student_png" alt="" style="width: 20rem;height: 20rem;"/>
					<span style="font-weight: normal;font-size: 16rem;color: #222222;margin: 0 6rem">{{ i.subject_name }}</span>
					<div :style="{background: bg(i.hierarchy)}" style="width: 32rem;height: 18rem;border-radius: 4rem;font-size: 12rem;color: #FFF;text-align: center">
						{{ i.hierarchy + "层" }}
					</div>
				</div>
				<div style="font-weight: normal;font-size: 14rem;color: #888888;margin-top: 16rem;margin-bottom: 8rem">
					<span style="display: flex"><span style="display: inline-block;width: 46rem">班级:</span> <span>{{ i.class_name }}</span></span>
				</div>
				<div style="font-weight: normal;font-size: 14rem;color: #888888;">
					<span>教师: {{ i.teacher_name }}</span>
				</div>
			</div>
		</div>
		<div v-else style="height: 100%;display: flex;align-items: center">
			<t-result style="margin-top: -68rem" text="暂无数据" type="empty"></t-result>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.ClassSchedule-main {
	width: 1240rem;
	height: 526rem;
	background: #FFFFFF;
	border-radius: 8rem;
	padding: 24rem;
	box-sizing: border-box;
}

.item-box {
	width: 176rem;
	min-height: 122rem;
	background: #F6F9FF;
	margin-top: 24rem;
	padding: 18rem;
}
</style>