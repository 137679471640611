<template>
  <div>
    <div  style="text-align: center;padding: 40rem 0;color:red;background-color: #fff;">
      未完成此工作
    </div>
  </div>
</template>

<script>
export default {
  name: "empty"
}
</script>

<style lang="scss" scoped>

</style>
