<script>

import LDialog from "@/views/edu/edu-info/student-manager/components/l-dialog/l-dialog.vue";

export default {
	name: "InfoLeft",
	components: {LDialog},
	props: {
		oss: {
			type: String,
			default: "",
		},
		value: {
			student_gender: "",
			obj_school: "",
		},
		allInfo: {},
	},
	data() {
		return {
			gender: ["female.png", "male.png"],
			studentProfile: [
				{field: "学号", value: "student_no"},
				{field: "出生日期", value: "birthday"},
				{field: "民族", value: "nation_name"},
				{field: "校区", value: "school_name"},
				{field: "年级", value: "grade_name"},
				{field: "行政班", value: "class_name"},
				{field: "学习方向", value: "learning_direction"},
				{field: "专业方向", value: "major"},
				{field: "班主任", value: "teacher_name"},
				{field: "宿舍", value: "dormitory"},
				{field: "政治面貌", value: "political_outlook"},
			],
			dialogVisible: false,
		};
	},
	methods: {
		onAllInformation() {
			this.dialogVisible = !this.dialogVisible;
		},
	},
};
</script>

<template>
	<div class="info-left-main">
		<div style="align-items: center;display: flex">
			<img :src="oss+'people.png'" alt="" style="width: 30.03rem;height: 27.6rem">
			<span style="width: 96rem;height: 34rem;font-weight: normal;font-size: 24rem;color: #1E3AA2;margin-left: 16rem">基础信息</span>
		</div>

		<div style="display: flex;margin-top: 24rem">
			<img v-if="allInfo.base.photo" :src="allInfo.base.photo" alt="" style="width: 208rem;height: 272rem;">
			<img v-else alt="" src="../../../../../../assets/img/default-header.png" style="width: 208rem;height: 272rem;">
			<div style="margin-left: 30rem;flex: 1">
				<div style="flex: 1;display: flex;align-items: end;height: 40rem;justify-content: space-between">
					<div>
						<span style="width: 72rem;height: 18rem;font-weight: 600;font-size: 24rem;color: #333333;">{{ value.student_name }}</span>
						<img :src="value.student_gender === '女' ? oss+gender[0] : oss+gender[1]" alt="" style="width: 20rem;height: 20rem;margin-left: 16rem">
					</div>
					<div style="height: 40rem;background: linear-gradient( 90deg, #FFB152 0%, #FFBB00 100%);border-radius: 20rem 0 0 20rem;margin-right: -24rem;align-items: center;display: flex;padding-right: 16rem">
						<img :src="oss+'school.png'" alt="" style="width: 32rem;height: 32rem;margin-left: 5rem">
						<span style="width: 64rem;height: 22rem;font-weight: normal;font-size: 16rem;color: #FFDC92;margin: 0 8rem">目标大学</span>
						<span style="height: 22rem;font-weight: 400;font-size: 16rem;color: #FFFFFF;">{{ value.obj_school }}</span>
					</div>
				</div>
				<div style="margin-top: 8rem">
					<div style="display: grid; grid-template-columns: repeat(3, 1fr); gap: 1rem; margin-top: 24rem;">
						<div v-for="(item, index) in studentProfile" :key="index" style="display: flex; min-height: 18rem;margin-bottom: 24rem">
							<span style="min-width: 80rem;height: 18rem;font-weight: normal;font-size: 14rem;color: #888888;display: inline-block">{{ item.field }}</span>
							<span style="min-height: 18rem;font-size: 14rem;color: #3C3C3C;">{{ value[item.value] }}</span>
						</div>
					</div>
				</div>
				<el-button plain style="width: 116rem;height: 40rem;border-radius: 4rem;font-weight: 400;font-size: 14rem;display: flex;align-items: center;justify-content: center" type="primary" @click="onAllInformation">
					全部信息<i class="el-icon-arrow-right"></i>
				</el-button>
			</div>
		</div>

		<l-dialog :all-info="allInfo" :visible.sync="dialogVisible"></l-dialog>
	</div>
</template>

<style lang="scss" scoped>
.info-left-main {
	width: 970rem;
	min-height: 356rem;
	background: #FFF;
	border-radius: 8rem;
	box-sizing: border-box;
	padding: 14rem 24rem 32rem
}
</style>