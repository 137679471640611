export const tableMethods = {

    sortChange(column, prop, order){
        this.$emit('onSortChange', column, prop, order)
    },
    /**
     * @description 表格的操作
     * @param row  当前行的数据
     * @param text 操作按钮的文字
     * @param index 操作按钮的下标
     * @param handle 操作按钮的配置信息
     */
    onHandle(row, text, index,handle) {
        this.$emit('onHandle', row, text, index,handle)
    },
    onSwitch(row, index) {
        this.$emit('onSwitch', row, index)
    },
}
// 表格props
export const tableProps = {
    // 权限判定模式
    permission:{
        type:Boolean,
        default:()=>false
    },
    // 是否需要分页
    hasPagination:{
        type:Boolean,
        default:()=>true
    },
    // 加载中
    loading: {
        type: [Number, Boolean],
        default() {
            return undefined;
        }
    },
    // 表格事件
    // 参考 https://element.eleme.cn/#/zh-CN/component/table#table-events
    events: Object,
    // 表格属性
    // 参考 https://element.eleme.cn/#/zh-CN/component/table#table-attributes
    property: Object,
    // 表格配置
    // prop 字段名
    // label 列展示名
    // width 列宽
    // type 列的类型   可选值: selection/index/expand
    // handle 是否为操作列
    // render {return : Array[string]} 渲染操作 , 返回数组 没有不写即可
    tableConfig: Array,
    // 表格数据
    tableData: Array,
    // total :  总数据数量
    total: Number,
    // currentPage :  当前页码
    currentPage: Number,
    // 每页数据量
    pageSize: {
        type: Number,
        default() {
            return 20
        }
    },
    empty_text: String,
}
// 表格顶部组件方法
export const topMethods = {

}
// 表格顶部组件props
export const topProps = {
    searchButtons:{
        type:Array,
        default:()=>["search","reset"]
    },
    searchConfig: Array,
    loading:[Number,Boolean],
    isCheckInputBox: Boolean,
    resetNoRequest:{
        type: Boolean,
        default() {
            return false
        }
    },
}
